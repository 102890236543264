import React from "react"
//import { Link } from "gatsby"
//import Button from "./Button"

const FreemiumCompareGrid = props => {
  return (
    <section className="freemium-compare-grid">
      <h1>Pricing options</h1>
      <p>
        What do you get for free? And what do you need to pay for? <br />
        Have a look below!
      </p>
      <div className="grid-container">
        <div className="grid-header1">
          <b>Feature</b>
        </div>
        <div className="grid-header2">
          <b>Free</b>
        </div>
        <div className="grid-header3">
          <b>Premium</b>
        </div>

        <div className="grid-item1">
          <b>Stores</b>
        </div>
        <div className="grid-item2">5</div>
        <div className="grid-item3">Umlimited</div>

        <div className="grid-item1">
          <b>Items</b>
        </div>
        <div className="grid-item2">125</div>
        <div className="grid-item3">Umlimited</div>

        <div className="grid-item1">
          <b>Item categories</b>
        </div>
        <div className="grid-item2">Unlimited</div>
        <div className="grid-item3">Umlimited</div>

        <div className="grid-item1">
          <b>Shopping trips</b>
        </div>
        <div className="grid-item2">Unlimited</div>
        <div className="grid-item3">Umlimited</div>

        <div className="grid-item1">
          <b>Shopping lists</b>
        </div>
        <div className="grid-item2">Unlimited</div>
        <div className="grid-item3">Umlimited</div>

        <div className="grid-item1">
          <b>Convert shopping lists into trips</b>
        </div>
        <div className="grid-item2">
          <i className="fas fa-check"></i>
        </div>
        <div className="grid-item3">
          <i className="fas fa-check"></i>
        </div>

        <div className="grid-item1">
          <b>See item price history</b>
        </div>
        <div className="grid-item2">
          <i className="fas fa-check"></i>
        </div>
        <div className="grid-item3">
          <i className="fas fa-check"></i>
        </div>

        <div className="grid-item1">
          <b>See spending charts</b>
        </div>
        <div className="grid-item2">
          <i className="fas fa-check"></i>
        </div>
        <div className="grid-item3">
          <i className="fas fa-check"></i>
        </div>

        <div className="grid-item1">
          <b>Up and coming features</b>
        </div>
        <div className="grid-item2">
          <i className="fas fa-question"></i>
        </div>
        <div className="grid-item3">
          <i className="fas fa-check"></i>
        </div>

        <div className="grid-item1">
          <b>Price</b>
        </div>
        <div className="grid-item2">Free</div>
        <div className="grid-item3">
          2,99€ per month /
          <br />
          29,99€ per year
        </div>
      </div>
      <a href="https://app.shoppingtracker.app/signup">
        <button className="button">Try it out!</button>
      </a>
    </section>
  )
}

export default FreemiumCompareGrid
