import React from "react"

const ProductFeatures = props => {
  return (
    <section className="product-features">
      <h1>YOU are in control</h1>
      <div className="core-features">
        <div className="core-feature-1">
          <div className="core-feature-1-image">
            <i className="fas fa-shopping-basket fa-6x"></i>
          </div>
          <h2>Add your items.</h2>
          <p>
            Add the items that YOU are buying. No clutter resulting from items
            you have never bought
          </p>
        </div>
        <div className="core-feature-3">
          <div className="core-feature-3-image">
            <i className="fas fa-store fa-6x"></i>
          </div>
          <h2>Add stores you personally visit.</h2>
          <p>
            Add the stores that YOU buy from. Different country? Different
            stores? No problem, since YOU are the one adding the stores.
          </p>
        </div>
        <div className="core-feature-2">
          <div className="core-feature-2-image">
            <i className="fas fa-clipboard-list fa-6x"></i>
          </div>
          <h2>Know the average price of your shopping list .</h2>
          <p>
            Using the prices that YOU have collected from YOUR shopping trips,
            YOU will have a much better idea how much YOUR shopping list should
            work out at each store.
          </p>
        </div>
      </div>
    </section>
  )
}

export default ProductFeatures
