import React from "react"
//import Button from "./Button"

const CallToAction = props => {
  return (
    <section className="cta">
      <h1>Ultimately, plan and budget better.</h1>
      <p>All of this with the data that YOU collect.</p>
      <p>Still not sure? Try out the demo account before registering</p>
      <a href="https://app.shoppingtracker.app/demo">
        <button className="button">Try it out before registering!</button>
      </a>
    </section>
  )
}

export default CallToAction
