import React from "react"
//import { Link } from "gatsby"
//import Button from "./Button"

const ValueProp = props => {
  return (
    <section className="value-prop">
      <div className="value-prop-content">
        <div className="main-message">
          <h1>Keep track of your shopping</h1>
          <p className="main-subtitle">
            An app to keep track of your shopping trips, allowing you to know
            how much you spent, where and on what. Use this information to
            create shopping lists, allowing you to see what you should expect to
            spend at each store.
          </p>
          <h2>No need to unwittingly over spend again</h2>
          <a href="https://app.shoppingtracker.app/signup">
            <button className="button">Try it out!</button>
          </a>
        </div>
        <div className="main-photo"></div>
      </div>
    </section>
  )
}

export default ValueProp
