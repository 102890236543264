import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ValueProp from "../components/ValueProp"
//import SocialProof from "../components/SocialProof"
import ProductBenefits from "../components/ProductBenefits"
import ProductFeatures from "../components/ProductFeatures"
//import Testimonials from "../components/Testimonials"
import CallToAction from "../components/CallToAction"
import EmailListForm from "../components/EmailListForm"
import FreemiumCompareGrid from "../components/FreemiumCompareGrid"
//import TargetMarket from "../components/TargetMarket"

const IndexPage = () => (
  <Layout>
    <SEO title="Shopping Tracker" />
    <ValueProp />
    <ProductBenefits />
    <ProductFeatures />
    <CallToAction />
    <FreemiumCompareGrid />
    <EmailListForm />
  </Layout>
)

export default IndexPage
