import React from "react"

const ProductBenefits = props => {
  return (
    <section className="product-benefits">
      <div className="product-benefits-group">
        <div className="product-benefit-1-tagline">
          Know how much you spent and where
        </div>
        <div className="product-benefit-1-image"></div>
        <div className="product-benefit-2-tagline">
          Know how much specific items cost on average at each store
        </div>
        <div className="product-benefit-2-image"></div>
        <div className="product-benefit-3-tagline">
          Know how the prices of the items varied over time
        </div>
        <div className="product-benefit-3-image"></div>
      </div>
    </section>
  )
}

export default ProductBenefits
