import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import * as styles from "../styles/components/_email-list-form.scss"

const EmailListForm = () => {
  const [email, setEmail] = useState("")
  const [gdpr, setGDPR] = useState(false)
  const [check, setCheck] = useState("")
  const [message, setMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    let gdprResponse = "N"
    if (gdpr) {
      gdprResponse = "Y"
    }

    console.log(email)

    if (gdprResponse === "N") {
      alert(
        "Please acknowledge that you wish to receive emails from us, or else we won't be able to email you."
      )
      //toggleShowMessage()
    } else if (check === "") {
      addToMailchimp(email, {
        //FNAME: "",
        //LNAME: "",
        "gdpr[42537]": gdprResponse,
      })
        .then(data => {
          console.log(data)
          alert(data.msg)
        })
        .catch(error => {
          console.log("Error thrown")
          // Errors in here are client side
          // Mailchimp always returns a 200
          console.log(error)
        })
    }
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const handleGDPRChange = event => {
    setGDPR(!gdpr)
  }

  const handleCheckChange = event => {
    setCheck(event.currentTarget.value)
  }

  const toggleShowMessage = () => {
    setShowMessage(!showMessage)
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="EmailListFormBackground"
      id="SignUpForm"
    >
      <h2>Subscribe to my email list!</h2>
      <div className="EmailListForm">
        <input
          placeholder="Email address"
          className="EmailListForm.EmailInput"
          name="email"
          type="text"
          //style={{ width: "70%" }}
          onChange={handleEmailChange}
        />
        <br />
        <br />
        <label>
          <input
            name="gdpr_42537"
            type="checkbox"
            checked={gdpr}
            onChange={handleGDPRChange}
          />{" "}
          <span className="styles.EmailListForm.Text">
            Yes, I really want you to keep me up to date, and I know that this
            involves sending me the occasional email. I am also well aware that
            I will be able to unsubscribe whenever I want
          </span>
          <br />
          <br />
          <span className="styles.Text">
            We use Mailchimp as our marketing platform. By clicking below to
            subscribe, you acknowledge that your information will be transferred
            to Mailchimp for processing.{" "}
            <a href="https://mailchimp.com/legal/" target="_blank">
              Learn more about Mailchimp's privacy practices here.
            </a>
          </span>
          <br />
        </label>
        <input
          type="text"
          hidden={true}
          name="b_ca7df75c561b2ffdabea930e6_a7186d0953"
          tabIndex="-1"
          value=""
          onChange={handleCheckChange}
        ></input>
        <button className="button" type="submit">
          Subscribe
        </button>
        <br />
        <span hidden={!showMessage} className="styles.Message">
          {message}
        </span>
      </div>
    </form>
  )
}

export default EmailListForm
